import React from "react";
import './home.css'

import BackgroundSrc from '../assets/home/bg.png'

const Page = () => {
    return (<>
        <div className='home-container'>
            <div className='left'>
                <h3 className='title'>关于我们</h3>
                <div className='mission'>使命愿景</div>
                <div className='slogan'>创作长期有效的快乐，<br />赢得百万人热爱。</div>
                <div className='detail'>
                    海南真有趣互动娱乐有限公司，成立于2020年11月，注册资本1000万元人民币，是一家致力于研发精品游戏产品的软件企业。
                </div>
                <div className='detail'>
                    这里聚集着一群有趣的人，我们热爱游戏，享受创作，喜欢开发，并且才华横溢。我们聚集在一起协力合作，创造更有趣的游戏。通过我们的协力合作，希望创作长期有效的快乐，赢得百万人热爱。
                </div>
            </div>
            <img className='right' src={BackgroundSrc} alt='' ></img>
        </div>
    </>)
}

export default Page