import React from "react"
import './logo.css'
import logo from '../assets/logo.png'

const Header = () => {
  
    return (
      <div className='logo-container'>
        <img className='logo' src={logo} alt=""></img>
        <div className='desc'>
          <div className='brand'>海南真有趣互动娱乐</div>
          <div className='slogan'>SO FUNNY GAMES</div>
        </div>
      </div>
    );
};
  
export default Header;