import React from "react";
import line from "../../assets/contact/line.png"
import map from "../../assets/contact/map.png"
import "./index.css"

const Page = () => {

    const itemRender = (item) => (
        <div className="info-item" key={item.title}>
            <div className="line"><img alt={item.title} src={line} /></div>
            <div className="sub-title">{item.title}</div>
            <p>{item.content}</p>
        </div>
    )

    const list = [
        {
            title: "Address",
            content: "海南省澄迈县老城镇高新技术产业示范区海南生态软件园孵化楼三楼 2001"
        },
        // {
        //     title: "Tel",
        //     content: "0592 - 2956003"
        // },
        {
            title: "Email",
            content: "biz@xmfunny.com"
        }
    ]

    return (
        <div className="contact-container">
            <div className="info-wrap">
                <div className="title">联系我们</div>
                {list.map(item => itemRender(item))}
            </div>
            <div className="map">
                <img alt="地图" src={map} />
            </div>
        </div>)
}

export default Page