import React from "react";
import { NavLink } from "react-router-dom"
import Logo from './logo'

import './header.css'

const Header = () => {
    return (
        <header className='header'>
            <div className='content'>
                <Logo />
                <ul className='header-menu'>
                    <NavLink to="/home" activeClassName="active">我们</NavLink><hr />
                    <NavLink to="/games" activeClassName="active">游戏</NavLink><hr />
                    <NavLink to="/contact" activeClassName="active">联系</NavLink>
                </ul>
            </div>
        </header>)
}

export default Header
