import {
  BrowserRouter as Router, Route,
  Switch,
  Redirect
} from 'react-router-dom';
import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import Games from "./pages/Games";
import Home from "./pages/Home";

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
        {/* 使用Switch是为了将React默认的包容性路由变为排他性路由 */}
        {/* 包容性路由：/food 既能匹配到/ 又能匹配到/food   
                   排他性路由：只能匹配一个 /food就只能匹配到/food */}
        <div className="page-container">
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/home" push />} />
            {/*Redirect 是路由转化  即匹配到某一个路由转化到另一个路由  */}
            <Route path="/home" component={Home} />
            <Route path="/games" component={Games} />
            <Route path="/contact" component={Contact} />
          </Switch>
        </div>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
