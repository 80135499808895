import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom"
import './Footer.css'

import gonganIcon from "../assets/footer/gongan.png"
import prevBg from "../assets/footer/prev.png"
import nextBg from "../assets/footer/next.png"
import prevBgDisable from "../assets/footer/prev_disable.png"
import nextBgDisable from "../assets/footer/next_disable.png"

const Footer = () => {
    const link = ["/home", "/games", "/contact"]
    const [prev, setPrev] = useState(null)
    const [next, setNext] = useState(null)
    const { pathname } = useLocation()
    const [current, setCurrent] = useState(() => link.indexOf(pathname))
    // let currentIndex, prev, next

    const handleBtn = () => {
        setCurrent(link.indexOf(pathname))
        setPrev(link[current - 1] || null)
        setNext(link[current + 1] || null)
    }

    useEffect(() => {
        handleBtn()
    }, [pathname, current])


    return (
        <footer className='footer'>
            <div className='content'>
                <ul className='footer-menu'>
                    <div className='company'>海南真有趣互动娱乐有限公司</div>
                    <div className='company-en'>Hainan So Funny Interactive entertainment Co., Ltd.</div>
                    <div className='tips'><strong>健康游戏忠告：</strong>抵制不良游戏，拒绝盗版游戏，注意自我保护，谨防受骗上当，适度游戏益脑，沉迷游戏伤身，合理安排时间，享受健康生活</div>
                    <div className='copyright'>
                        <span className="text" onClick={() => { window.open("http://www.beian.gov.cn/portal/registerSystemInfo") }}><img className="gongan-icon" src={gonganIcon} /> 琼公安网备 46902302000525 号</span>
                        丨琼ICP备2021004365号-1
                    </div>
                    {/* 丨隐私保护政策丨问题反馈 */}
                    <div className='copyright-en'>Copyright © So Funny. All rights reserved.</div>
                </ul>
                <div className="separator"></div>
                <div className='footer-action'>
                    {prev ? <NavLink to={prev}><img src={prevBg} alt=""></img></NavLink> : <img src={prevBgDisable} alt="" />}
                    {next ? <NavLink to={next}><img src={nextBg} alt=""></img></NavLink> : <img src={nextBgDisable} alt="" />}
                </div>

            </div>
        </footer>)
}

export default Footer