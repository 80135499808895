import React from "react";
import wulala from "../../assets/games/wulala.png";
import xaingchang from "../../assets/games/xiangchang.png";
import "./index.css";
const Page = () => {
  const itemRender = (item) => (
    <div className="content" key={item.name_en}>
      <img alt="游戏" src={item.img} />
      <div className="name-en">{item.name_en}</div>
      <div className="name-zh">{item.name_zh}</div>
      <div className="desc">
        <div className="desc_label">{item.desc}</div>
        <div className="tags">
          {item.tags.map((item) => (
            <span key={item}>{item}</span>
          ))}
        </div>
      </div>
    </div>
  );

  const list = [
    {
      img: wulala,
      name_en: "IDLE ADVENTURERS",
      name_zh: "不休的乌拉拉",
      desc: "一款以石器时代为背景的组队社交放置手游全自动的放置战斗，全天候不间断收获，让你不再被打怪练级所束缚，尽情享受与小伙伴们一起并肩狩猎、捕获宠物的乐趣吧！",
      tags: ["放置", "挂机", "养成", "RPG", "联机"],
    },
    {
      img: xaingchang,
      name_en: "SAUSAGE MAN",
      name_zh: "香肠派对",
      desc: "外形搞怪但内核过硬的荒岛特训生存射击手游。 8km x 8km的大地图，有城镇，有野点，有树丛，有草丛，每局支持100名玩家对战。 超硬核射击体验，逼真的后坐力算法，真实的弹道物理计算，驾驶手感佳。",
      tags: ["吃鸡", "求生", "射击", "枪战", "多人联机", "多人对战", "第三人称"],
    },
  ];

  return (
    <div className="games-container">
      <div className="games-header">游戏</div>
      <div className="content-wrap">{list.map((item) => itemRender(item))}</div>
    </div>
  );
};

export default Page;
